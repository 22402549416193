






import { defineComponent } from "@vue/composition-api"
import { set } from "firebase/database"

import { RtbControlButton } from "@/components/ui"

import useJeopardAiBaseRef from "../Games/JeopardAI/use/useJeopardAiBaseRef"
import useJeopardPredefined from "../Games/JeopardAI/use/useJeopardPredefined"
import { Module } from "../Games/JeopardAI/enums"

export default defineComponent({
  name: "JeopardAiResetBtn",
  components: { RtbControlButton },
  setup() {
    const { ref: baseRef } = useJeopardAiBaseRef()
    const { predefined, firstRound } = useJeopardPredefined()

    function reset() {
      const update = predefined.value
        ? {
            rounds: {
              0: " ",
              1: {
                state: {
                  ...firstRound.value,
                  module: Module.QUESTIONS,
                  working: false
                }
              }
            }
          }
        : null

      set(baseRef.value, update)
    }

    return { reset }
  }
})
