var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RtbControlButton",
    { attrs: { sm: "" }, on: { click: _vm.reset } },
    [_c("SvgIcon", { attrs: { width: "20", height: "20", name: "replay" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }